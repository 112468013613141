import { Button, Popover } from "antd";
import React, { useEffect, useState } from "react";
import { FaArrowDown, FaPlusCircle } from "react-icons/fa";
import { MdOutlineHelp, MdRocket, MdRocketLaunch } from "react-icons/md";
import { RxAvatar } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { RootState, ownerSelector } from "../../store/store";
import LoginModal from "../Modals/LoginModal";
import RegisterModal from "../Modals/RegisterModal";
import {
  logOut,
  setCurrentWorkSpace,
  setOwner,
} from "../../store/slices/ownerSlice";
import { FaCaretDown, FaCrown, FaQuestion, FaRocket } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import WorkSpaceSelectionModal from "../Modals/WorkSpaceSelectionModal";
import { updateOwner } from "../../store/features/ownerActions";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";

type Props = {};

const Navbar = (props: Props) => {
  const dispatch: ThunkDispatch<RootState, undefined, Action> = useDispatch();
  const { owner } = useSelector(ownerSelector);
  const [logginIn, setLogginIn] = useState(false);
  const [registering, setRegistering] = useState(false);
  const [showProfileOptions, setShowProfileOptions] = useState(false);
  const [showWorkSpaceOptions, setShowWorkSpaceOptions] = useState(false);
  const navigate = useNavigate();

  const handleUpdateOwner = async () => {
    const res = await dispatch(
      updateOwner({
        ownerId: owner?._id,
        name: owner?.name,
        email: owner?.email || null,
        linkedinError: "",
      })
    );
    if (res.payload) {
      dispatch(setOwner(res.payload.data));
      dispatch(setCurrentWorkSpace(res.payload.data.workspaces[0]));
    }
  };

  useEffect(() => {}, [owner]);

  return (
    <>
      {owner?.linkedinError && (
        <div className=" p-2  bg-mainColor/90 text-white flex justify-center items-center text-sm">
          <p>{owner.linkedinError}</p>
          <button
            onClick={() => handleUpdateOwner()}
            className="p-1  bg-white text-mainColor rounded-lg ml-2"
          >
            I understand
          </button>
        </div>
      )}
      {owner?.usageError && (
        <div className=" p-2 text-xs  bg-mainColor/90 text-white flex justify-center items-center">
          <p>{owner?.usageError}</p>
        </div>
      )}
      <nav
        className={`w-full bg-white mx-auto z-50 justify-center flex items-center text-xs`}
      >
        {/* <div
          onClick={() => setShowWorkSpaceOptions(true)}
          className="p-3 mx-2 border-2 border-mainColor rounded-lg cursor-pointer bg-primary hover:bg-mainColor/20 flex justify-center items-center gap-4"
        >
          {currentWorkspace && (
            <p className="text-xs font-semibold whitespace-nowrap">
              {currentWorkspace.name}
            </p>
          )}
          <span>
            <FaCaretDown size={15} />
          </span>
        </div> */}
        <div className="flex  items-center gap-4 w-full justify-end p-2">
          {/* <button className="flex p-2 justify-center items-center rounded-lg gap-2 text-mainColor border-2 border-mainColor text-sm font-semibold">
            <FaCrown size={20} />
            <p>Subscribe</p>
          </button> */}
          <Popover content={<p className="text-xs">Help Center</p>}>
            <button
              onClick={() =>
                window.open("https://intercom.help/leadseeder/en/", "_blank")
              }
              className="flex p-1 justify-center items-center rounded-full gap-2 text-mainColor border-2 border-mainColor text-sm font-semibold"
            >
              <FaQuestion />
            </button>
          </Popover>
          <div className="flex justify-center items-center gap-4">
            <button
              onClick={() => navigate("/campaigns/create")}
              className="flex gap-2 border p-2 justify-center items-center  rounded-md text-white bg-mainColor shadow-md"
            >
              <MdRocketLaunch className="" size={20} />
              <p>Create Campaign</p>
            </button>
            {/* <button className="flex gap-2  p-2 font-semibold rounded-md text-mainColor">
              <MdOutlineHelp size={20} />
            </button> */}

            <div className="relative flex items-center">
              <button
                // onClick={() => setShowProfileOptions(!showProfileOptions)}
                className="flex gap-2  p-2 font-semibold rounded-md text-black items-center"
              >
                {owner?.picture !== undefined && owner?.picture ? (
                  <div className="flex gap-2 justify-center items-center">
                    <img
                      src={owner?.picture}
                      alt=""
                      className="w-8 h-8 rounded-full"
                    />
                    <p>{owner?.name}</p>
                  </div>
                ) : (
                  <>
                    <RxAvatar size={25} />
                    <p>{owner?.name}</p>
                  </>
                )}
              </button>

              {showProfileOptions && (
                <div className="absolute flex flex-col gap-2 top-10 right-0 bg-white p-4 rounded-md shadow-lg">
                  <div>
                    {/* <p>{owner.email}</p>
                    <p>{owner.name}</p> */}
                  </div>
                  <button
                    onClick={() => dispatch(logOut())}
                    className="text-white p-2 rounded-md shadow-md bg-mainColor"
                  >
                    Logout
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
      {showWorkSpaceOptions && (
        <WorkSpaceSelectionModal
          onClose={() => setShowWorkSpaceOptions(false)}
        />
      )}
      {/* {logginIn && <LoginModal onClose={() => setLogginIn(false)} />}
      {registering && <RegisterModal onClose={() => setRegistering(false)} />} */}
    </>
  );
};

export default Navbar;
