import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

export const getAllConnections = createAsyncThunk(
  "dashboard/getAllConnections",
  async (ownerId: string, { rejectWithValue }) => {
    try {
      const response = await axios.post("/dashboard/getAllConnections", {
        ownerId,
      });

      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getAllRequests = createAsyncThunk(
  "dashboard/getAllRequests",
  async (data: { ownerId: string; type: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post("/dashboard/getAllRequests", data);

      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getQueuedActions = createAsyncThunk(
  "dashboard/getQueuedActions",
  async (ownerId: string, { rejectWithValue }) => {
    try {
      const response = await axios.post("/dashboard/getQueuedActions", {
        ownerId,
      });

      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getCampaignData = createAsyncThunk(
  "dashboard/getCampaignData",
  async (campaignId: string, { rejectWithValue }) => {
    try {
      const response = await axios.post("/campaign/getCampaignData", {
        campaignId,
      });

      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getProfileAnalytics = createAsyncThunk(
  "dashboard/getProfileAnalytics",
  async (ownerId: string, { rejectWithValue }) => {
    try {
      const response = await axios.post("/dashboard/getProfileAnalytics", {
        ownerId,
      });

      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);
