import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { ownerSelector } from "../../store/store";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
import { setCurrentWorkSpace, setOwner } from "../../store/slices/ownerSlice";

type Props = {
  onClose: () => void;
};

const AddMemberModal = (props: Props) => {
  const { owner, currentWorkspace } = useSelector(ownerSelector);
  // console.log(owner);
  // console.log(currentWorkSpace);
  const [newMemberData, setNewMemberData] = useState({
    email: "",
    role: "",
  });
  const dispatch = useDispatch();

  const handleMemberAdd = async () => {
    try {
      if (newMemberData.email === owner?.email) {
        toast.error("You can't add yourself as a member");
        return;
      }

      if (newMemberData.email === "") {
        toast.error("Please enter an email");
        return;
      }

      if (newMemberData.role === "") {
        toast.error("Please select a role");
        return;
      }

      const res = await axios.post("/teams/addTeamMember", {
        ownerId: owner?._id,
        memberEmail: newMemberData.email,
        role: newMemberData.role,
        workspaceId: currentWorkspace?._id,
      });

      if (res.data) {
        toast.success("Member added successfully");
        dispatch(setOwner(res.data.owner));
        dispatch(setCurrentWorkSpace(res.data.owner.workspaces[0]));
        props.onClose();
      } else {
        toast.error(res.data.message);
      }
    } catch (error: any) {
      // toast.error(error.res);
      console.log(error);
    }
  };
  return (
    <div>
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
        <div className="bg-white p-4 rounded-md w-[500px] ">
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-semibold">Add Member</h1>
            <MdClose
              onClick={props.onClose}
              size={20}
              className=" cursor-pointer"
            />
          </div>
          {/* <form action="" className="flex flex-col gap-4"> */}
          <div className="flex flex-col gap-2">
            <label htmlFor="email">Email</label>
            <input
              onChange={(e) =>
                setNewMemberData({ ...newMemberData, email: e.target.value })
              }
              type="email"
              name="email"
              id="email"
              className="p-2 border border-gray-400 rounded-md"
            />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="role">Role</label>
            <select
              defaultValue="MEMBER"
              onChange={(e) =>
                setNewMemberData({ ...newMemberData, role: e.target.value })
              }
              name="role"
              id="role"
              className="p-2 border border-gray-400 rounded-md"
            >
              <option value="" selected>
                --Insert A Value--
              </option>
              <option value="ADMIN">Admin</option>
              <option value="EDITOR">Editor</option>
              <option value="VIEWER">Viewer</option>
            </select>
          </div>
          <button
            onClick={handleMemberAdd}
            className="bg-mainColor my-4 text-white p-2 rounded-md"
          >
            Add Member
          </button>
          {/* </form> */}
        </div>
      </div>
    </div>
  );
};

export default AddMemberModal;
