import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { Popover } from "antd";
import { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useWindowResize } from "../../hooks/useWindowResize";
import {
  RootState,
  campaignSelector,
  dashboardSelector,
  ownerSelector,
} from "../../store/store";
import { deleteAllRequests } from "../../utils";
import LinkedinGraphs from "./LinkedinGraphs";
import Progresscard from "./Progresscard";
import ProspectingStatusCard from "./ProspectingStatusCard";
import StatsCardReusable from "./StatsCardReusable";
import LinkedinStats from "./LinkedinStats";
import axios from "../../utils/axios";
import { setSingleCampaign } from "../../store/slices/campaignSlice";

type Props = {};

const DashboardMain = (props: Props) => {
  const { owner } = useSelector(ownerSelector);
  const { campaignsData, linkedinData, dashCollapsed } =
    useSelector(dashboardSelector);
  const { singleCampaign, selectedCampaign } = useSelector(campaignSelector);
  const [totalProfileVisits, setTotalProfileVisits] = useState(0);
  const [percentageOfVisit, setPercentageOfVisit] = useState(0);
  // const [ownerLinkedinData, setOwnerLinkedinData] = useState();
  //const [ownerConnections, setOwnerConnections] = useState(0);
  //const [allRequests, setAllRequests] = useState(0);
  const [showDelete, setShowDelete] = useState(false);
  const dispatch: ThunkDispatch<RootState, undefined, Action> = useDispatch();
  const { width } = useWindowResize();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        if (selectedCampaign === "") return;
        const { data } = await axios.post("/campaign/getCampaignData", {
          campaignId: selectedCampaign,
        });
        // console.log(data);
        dispatch(setSingleCampaign(data.campaign));
      } catch (error) {
        toast.error("Error fetching campaign data");
        console.log(error);
      }
    })();
  }, [selectedCampaign]);
  useEffect(() => {
    console.log("Render");
    if (singleCampaign) {
      // console.log(singleCampaign);
      let visitActions: any[] = [];
      singleCampaign?.prospects?.map((prospect: any) => {
        return prospect.actions.filter((action: any) => {
          if (action.campaignName === singleCampaign.campaignName) {
            action.campaignActions.map((campaignAction: any) => {
              if (campaignAction.type === "visit") {
                visitActions.push(campaignAction);
              }
            });
          }
        });
      });
      const totalVisits = visitActions.filter(
        (visit) => visit.done === "DONE"
      ).length;
      setTotalProfileVisits(totalVisits);
      const percentage = Math.round((totalVisits / visitActions.length) * 100);
      setPercentageOfVisit(percentage);
    }
  }, [singleCampaign]);

  const handleRequestsDelete = async () => {
    try {
      const res = await deleteAllRequests(owner?._id as string);
      console.log(res);
      setShowDelete(false);
      toast.success("Requests deleted successfully");
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className="flex w-full gap-4   noScroll">
      <div className=" w-[70%] flex flex-col gap-4">
        <div className="flex w-full gap-4">
          {/* <Progresscard /> */}
          <LinkedinStats />
          <ProspectingStatusCard />

          {/* <EmailProgersscard /> */}
        </div>
        <div>
          <LinkedinGraphs />
        </div>
      </div>
      <div className="flex flex-col w-[30%] gap-4">
        <div className="bg-white    justify-center items-center p-6 shadow-lg rounded-md relative">
          {/* <p className="absolute top-2 right-2 rounded-lg text-sm bg-mainColor/80 p-2 text-white flex items-center justify-center">
            <FaCrown className="inline-block mr-2 " />
            <p>{owner.planhistory[0].planName}</p>
          </p> */}
          <div className="w-full flex justify-center items-center flex-col gap-6">
            <div
              onClick={(e) => {
                window.open(
                  `https://www.linkedin.com/in/${owner?.ownerEntityUrn}/`,
                  "_blank"
                );
              }}
              className="flex flex-col justify-center items-center gap-2 cursor-pointer"
            >
              <img
                className={`rounded-full ${
                  width < 1920 ? " w-20 h-20" : "w-28 h-28"
                }`}
                src={owner?.picture}
                alt="placeholder"
              />
              <p className="font-semibold">{owner?.name}</p>
            </div>
            <div className="flex justify-center items-center w-full gap-4">
              <span
                onClick={(e) => {
                  window.open(
                    `https://www.linkedin.com/mynetwork/invite-connect/connections/`,
                    "_blank"
                  );
                }}
                className="flex flex-col justify-center items-center cursor-pointer"
              >
                <p className=" font-semibold">
                  {linkedinData.numOfConnections || 0}
                </p>
                <p className="text-xs">Connections</p>
              </span>
              <span
                onClick={(e) => {
                  window.open(
                    `https://www.linkedin.com/mynetwork/invitation-manager/sent/`,
                    "_blank"
                  );
                }}
                className="relative flex flex-col justify-center items-center  cursor-pointer"
              >
                <Popover
                  className=""
                  content="Delete all pending connection requests"
                >
                  {/* <span
                    onClick={() => setShowDelete(true)}
                    className="absolute cursor-pointer -right-2 p-1 rounded-full bg-red-200 text-red-400 -top-3"
                  >
                    <MdDelete size={15} />
                  </span> */}
                </Popover>
                <p className=" font-semibold">
                  {linkedinData.numOfPendingRequests || 0}
                </p>
                <p className="text-xs">Pending</p>
              </span>
              <span
                onClick={(e) => {
                  window.open(
                    `https://www.linkedin.com/analytics/profile-views/`,
                    "_blank"
                  );
                }}
                className="flex flex-col justify-center items-center cursor-pointer "
              >
                <p className=" font-semibold">
                  {linkedinData.numberOfProfileViews || 0}
                </p>
                <p className="text-xs">Profile views</p>
              </span>
            </div>
          </div>

          {showDelete && (
            <div className="absolute rounded-md backdrop-blur-sm top-0 bg-gray-900/30 w-full h-full left-0 flex justify-center">
              <div className=" w-5/6 mx-auto my-auto flex flex-col justify-center items-center gap-2 top-10 right-0 bg-white p-4 shadow-lg rounded-md">
                <div className="text-sm">
                  <p>Are you sure you want to delete all requests?</p>
                </div>
                <div className="flex gap-4">
                  <button
                    onClick={() => setShowDelete(false)}
                    className="bg-mainColor text-sm text-white p-2 rounded-lg"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => handleRequestsDelete()}
                    className="bg-red-500 text-sm text-white p-2 rounded-lg"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="w-full mt-8">
          {!dashCollapsed && (
            <StatsCardReusable
              info="Number of profile visited on this campaign"
              icon={<FaEye size={25} />}
              statNumber={totalProfileVisits}
              statLabel="Profile Visits"
              progressValue={percentageOfVisit}
              percentageLabel="Visit Progress"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardMain;
