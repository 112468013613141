import React, { useEffect, useState } from "react";
import DashboardMain from "../components/Dashboard/DashboardMain";
import { useDispatch, useSelector } from "react-redux";
import { RootState, campaignSelector, ownerSelector } from "../store/store";
import { Navigate } from "react-router-dom";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { getCampaignData } from "../store/features/dashboardActions";
import { setSelectedCampaign } from "../store/slices/campaignSlice";

type Props = {};

const Home = (props: Props) => {
  const { owner, campaigns } = useSelector(ownerSelector);
  const { selectedCampaign } = useSelector(campaignSelector);
  const dispatch: ThunkDispatch<RootState, undefined, Action> = useDispatch();

  useEffect(() => {
    // console.log(selectedCampaign);
  }, [selectedCampaign]);

  return (
    <section className="p-4 mb-4 flex flex-col gap-6 max-h-[90vh] overflow-y-scroll noScroll">
      <div className="flex items-center justify-between">
        <h1 className=" text-2xl font-semibold">Hello {owner?.name},</h1>
        {campaigns.length > 0 && (
          <div className="flex gap-2 justify-center items-center">
            <p className="font-semibold text-sm">Select Campaign :</p>
            <select
              value={selectedCampaign}
              onChange={(e) => {
                dispatch(setSelectedCampaign(e.target.value));
              }}
              className="p-1 text-xs appearance-none border-2 border-mainColor rounded-md outline-none"
            >
              {campaigns.map((campaign: any) => {
                if (campaign?.campaignStatus !== "ARCHIVED") {
                  return (
                    <option key={campaign._id} value={campaign._id}>
                      {campaign.campaignName}
                    </option>
                  );
                }
              })}
            </select>
          </div>
        )}
      </div>
      <DashboardMain />
    </section>
  );
};

export default Home;
