import { Popover } from "antd";
import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import { MdInfoOutline, MdMessage } from "react-icons/md";

type StatsReusableCardProps = {
  info?: string;
  icon: React.ReactNode;
  statLabel: string;
  statNumber: number;
  progressValue: number;
  percentageLabel?: string;
  className?: string;
};

const StatsCardReusable = ({
  className,
  info,
  icon,
  statLabel,
  statNumber,
  progressValue,
  percentageLabel,
}: StatsReusableCardProps) => {
  return (
    <div
      className={`bg-white rounded-lg  flex items-start justify-evenly p-8 shadow-md relative ${className}`}
    >
      <div className="absolute right-2 top-4">
        <Popover
          placement="top"
          content={<p className="text-xs">{info || "Information"}</p>}
        >
          <MdInfoOutline size={16} />
        </Popover>
      </div>
      <span className="flex flex-col justify-center items-center gap-4">
        <span className="text-mainColor p-3 text-5xl bg-primary rounded-full ">
          {icon}
        </span>
        <p className="text-2xl font-semibold">{statNumber || 0}</p>
        <p className="text-xs">{statLabel}</p>
      </span>
      <span className="flex flex-col justify-center items-center gap-4">
        <CircularProgressbar
          styles={{
            text: {
              fill: "#74A567",
            },
            path: {
              stroke: "#74A567",
            },
            trail: {
              stroke: "#EEF2F5",
            },
          }}
          className="h-24  text-white"
          value={progressValue || 0}
          text={`${progressValue || 0}%`}
        />
        <p className="text-xs">{percentageLabel || `Progress`}</p>
      </span>
    </div>
  );
};

export default StatsCardReusable;
