import React, { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { FaPencil } from "react-icons/fa6";
import { MdAddModerator, MdClose, MdDelete, MdPersonAdd } from "react-icons/md";
import AddMemberModal from "./AddMemberModal";
import { useDispatch, useSelector } from "react-redux";
import { ownerSelector } from "../../store/store";
import { fetchTeamMembers } from "../../utils";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import { setCurrentWorkSpace, setOwner } from "../../store/slices/ownerSlice";
import { Owner, Workspace } from "../../types";

type Props = {};

const MembersList = (props: Props) => {
  const [showAddmemberModal, setShowAddmemberModal] = useState(false);
  const { owner, currentWorkspace } = useSelector(ownerSelector);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState("");
  const [members, setMembers] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        const data = await fetchTeamMembers(
          owner?._id as string,
          currentWorkspace?._id as string
        );
        if (data) {
          console.log(data.members);
          setMembers(data.members);
        } else {
          setMembers([]);
        }
      } catch (error) {}
    })();
  }, [owner, currentWorkspace]);

  const handleMemberDelete = async (ownerId?: string, memberId?: string) => {
    try {
      const res = await axios.post("/teams/removeTeamMember", {
        ownerId: ownerId,
        memberId: memberId,
        workspaceId: currentWorkspace?._id,
      });

      if (res.data) {
        toast.success("Member deleted successfully");
        dispatch(setOwner(res.data.owner));
        dispatch(setCurrentWorkSpace(res.data.owner.workspaces[0]));
        setMembers(currentWorkspace?.members as any);
        setShowDeleteModal(false);
      } else {
        toast.error(res.data.message);
      }
    } catch (error: any) {
      toast.error("Error deleting member");
      console.log(error);
    }
  };

  return (
    <div className="w-full flex flex-col ">
      <div className="w-full flex  justify-between  my-4 border-b-2 border-mainColor font-semibold">
        <h1>Member's Name</h1>
        <h1>Email</h1>
        <div className="flex gap-8">
          <h2 className="w-20">Role</h2>
          <h2 className="w-20">Actions</h2>
        </div>
      </div>
      <div className="w-full max-h-[90vh] noScroll overflow-y-scroll">
        {members.length > 0 ? (
          members.map((member: any, index) => (
            <div
              className="w-full flex my-2 justify-between  items-center"
              key={member._id}
            >
              <h1 className="flex justify-center items-center gap-2">
                <img
                  className="w-8 h-8 rounded-full"
                  src={member.picture}
                  alt=""
                />
                {member.name}
              </h1>
              <h1>{member.email}</h1>
              <div className="flex justify-center items-center gap-8">
                <h2 className="w-20 p-1 bg-mainColor text-white text-sm text-center rounded-lg">
                  {currentWorkspace?.members[index]?.accessType
                    ? currentWorkspace.members[index].accessType
                    : "VIEWER"}
                </h2>

                <h2 className="w-20 text-center mx-auto flex justify-center items-center gap-4">
                  <MdDelete
                    onClick={() => {
                      setShowDeleteModal(true);
                      setSelectedMember(member._id);
                    }}
                    className="text-red-500 text-4xl p-2 bg-red-500/10 rounded-md cursor-pointer"
                  />
                  <FaPencil className="text-mainColor bg-primary p-2 text-4xl rounded-md cursor-pointer" />
                </h2>
              </div>
            </div>
          ))
        ) : (
          <div className="w-full justify-center items-center flex">
            <div className=" flex flex-col gap-4 justify-center items-center">
              <CiSearch size={30} className="text-mainColor " />
              <p>No members to show!</p>
              <button
                onClick={() => {
                  setShowAddmemberModal(true);
                }}
                className="text-white bg-mainColor flex rounded-md p-2  justify-center' items-center  gap-2 "
              >
                <MdPersonAdd size={25} className=" " />
                <p>Add Member</p>
              </button>
            </div>
          </div>
        )}
      </div>
      {showAddmemberModal && (
        <AddMemberModal onClose={() => setShowAddmemberModal(false)} />
      )}
      {showDeleteModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded-md w-[500px] ">
            <div className="flex justify-between items-center">
              <h1 className="text-2xl font-semibold">Delete Member</h1>
              <MdClose
                onClick={() => setShowDeleteModal(false)}
                size={20}
                className=" cursor-pointer"
              />
            </div>
            <div className="flex flex-col gap-2">
              <p>Are you sure you want to delete this member?</p>
            </div>
            <div className="flex flex-col gap-2">
              <button
                onClick={() => handleMemberDelete(owner?._id, selectedMember)}
                className="bg-red-400/20 border-2 border-red-500 my-4 text-red-500 p-2 rounded-md"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MembersList;
