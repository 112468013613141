import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { ownerSelector } from "../../store/store";
import { ICON } from "../assets";
import { setCurrentWorkSpace } from "../../store/slices/ownerSlice";
import { toast } from "react-toastify";

type Props = {
  onClose: () => void;
};

const WorkSpaceSelectionModal = (props: Props) => {
  const { owner, currentWorkspace } = useSelector(ownerSelector);
  const [selectedWorkspace, setSelectedWorkspace] = useState(currentWorkspace);
  const dispatch = useDispatch();

  const handleWorkspace = async () => {
    dispatch(setCurrentWorkSpace(selectedWorkspace));
    props.onClose();
    toast.success("Workspace switched successfully");
  };
  return (
    <div>
      <div className="fixed inset-0 bg-black backdrop-blur-sm bg-opacity-50 z-50"></div>
      <div className="fixed inset-0 flex justify-center items-center z-50">
        <div className="bg-white w-[30%]  flex flex-col gap-4  p-6 rounded-lg">
          <div className="w-full flex items-center justify-between">
            <h1 className="text-xl font-semibold">Switch Workspace</h1>
            <span onClick={props.onClose}>
              <MdClose className=" top-2 right-2 text-xl cursor-pointer" />
            </span>
          </div>
          <div className="max-h-[50vh] noScroll overflow-y-scroll flex flex-col gap-4 ">
            {owner?.workspaces.map((workspace: any) => (
              <div
                onClick={() => setSelectedWorkspace(workspace)}
                key={workspace._id}
                className={`${
                  selectedWorkspace?._id === workspace._id
                    ? "border-l-4 border-mainColor/50"
                    : "b border-l-4 border-primary"
                } flex w-full gap-4  p-4 rounded-lg bg-primary shadow-md justify-start items-center  cursor-pointer `}
              >
                <span className="p-2 bg-white rounded-full">
                  <img src={ICON} alt="" className="w-8 h-8 rounded-full" />
                </span>
                <p>{workspace.name}</p>
                <span className="text-xs bg-mainColor text-white rounded-lg p-2">
                  {workspace.type}
                </span>
                <p>
                  {workspace.members.length}{" "}
                  {workspace.members.length > 1 ? "Members" : "Member"}
                </p>
              </div>
            ))}
          </div>

          <button
            onClick={() => {
              handleWorkspace();
            }}
            className="bg-mainColor ml-auto text-white p-2 rounded-md"
          >
            Switch Workspace
          </button>
        </div>
      </div>
    </div>
  );
};

export default WorkSpaceSelectionModal;
