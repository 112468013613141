import { createSlice } from "@reduxjs/toolkit";
import {
  getAllConnections,
  getAllRequests,
  getCampaignData,
  getProfileAnalytics,
  getQueuedActions,
} from "../features/dashboardActions";

const initialState = {
  loading: false,
  error: null,

  campaignsData: {
    numOfActiveCampaigns: 0,
    pendingActions: 0,
    numberOfActionSent: 0,
    numberOfPendingActions: 0,
  },
  linkedinData: {
    numOfConnections: 0,
    numOfPendingRequests: 0,
    numberOfFollowers: 0,
    numberOfProfileViews: 0,
    numberOfSearchAppearances: 0,
    numberOfPostImpressions: 0,
  },

  selectedCampaign: {},
  dashCollapsed: false,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {
    setNumberOfActionSent: (state, action) => {
      state.campaignsData.numberOfActionSent = action.payload;
    },
    setNumberOfPendingActions: (state, action) => {
      state.campaignsData.numberOfPendingActions = action.payload;
    },
    setDashCollapsed: (state, action) => {
      state.dashCollapsed = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllConnections.fulfilled, (state, action) => {
        state.linkedinData.numOfConnections = action.payload.connections;
      })
      .addCase(getAllConnections.rejected, (state, action) => {
        //@ts-ignore
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getAllConnections.pending, (state) => {
        state.loading = true;
      });

    builder
      .addCase(getAllRequests.fulfilled, (state, action) => {
        // console.log(action.payload);
        state.linkedinData.numOfPendingRequests = action.payload.prospects;
        state.loading = false;
      })
      .addCase(getAllRequests.rejected, (state, action) => {
        //@ts-ignore
        state.error = action.payload;
        state.loading = false;
      });

    // Get Queued Actions
    builder
      .addCase(getQueuedActions.fulfilled, (state, action) => {
        state.campaignsData.pendingActions = action.payload.actions.length;
        state.loading = false;
      })
      .addCase(getQueuedActions.rejected, (state, action) => {
        //@ts-ignore
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getQueuedActions.pending, (state) => {
        state.loading = true;
      });

    // Get Campaign Data

    builder
      .addCase(getCampaignData.fulfilled, (state, action) => {
        state.selectedCampaign = action.payload.campaign;
        state.loading = false;
      })
      .addCase(getCampaignData.rejected, (state, action) => {
        //@ts-ignore
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getCampaignData.pending, (state) => {
        state.loading = true;
      });

    // Get Profile Data
    builder.addCase(getProfileAnalytics.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.linkedinData.numberOfFollowers = action.payload.Followers;
      state.linkedinData.numberOfProfileViews =
        action.payload["Profile viewers"];
      state.linkedinData.numberOfSearchAppearances =
        action.payload["Search appearances"];
      state.linkedinData.numberOfPostImpressions =
        action.payload["Post impressions"];
      state.loading = false;
    });
  },
});

export const {
  setNumberOfActionSent,
  setNumberOfPendingActions,
  setDashCollapsed,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
