import React from "react";
import SettingSider from "../components/Settings/SettingSider";
import ProfileSettings from "../components/Settings/ProfileSettings";
import QuotaSettings from "../components/Settings/QuotaSettings";

type Props = {};

const Settings = (props: Props) => {
  const currentPath = window.location.pathname;
  console.log(currentPath);
  return (
    <section className="w-full h-full flex flex-col gap-4">
      <h1 className="text-2xl font-semibold">Setting</h1>
      <div className="flex w-full h-full">
        <div className=" w-[20%] h-full border-r-2 border-mainColor/40">
          <SettingSider />
        </div>
        <div className="w-full px-4">
          {currentPath === "/settings/profile" && <ProfileSettings />}
          {currentPath === "/settings/account-activity" && <QuotaSettings />}
        </div>
      </div>
    </section>
  );
};

export default Settings;
