import React, { useEffect, useState } from "react";
import { FaLinkedin } from "react-icons/fa6";
import { CircularProgressbar } from "react-circular-progressbar";
import { useDispatch, useSelector } from "react-redux";
import { campaignSelector, dashboardSelector } from "../../store/store";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import { Popover } from "antd";
import { MdInfoOutline } from "react-icons/md";
import { setSingleCampaign } from "../../store/slices/campaignSlice";
import StatsCardReusable from "./StatsCardReusable";
import { HiMiniLink } from "react-icons/hi2";

type Props = {};

const Progresscard = (props: Props) => {
  const { selectedCampaign, singleCampaign } = useSelector(campaignSelector);
  const [totalProspects, setTotalProspects] = useState(0);
  const [invitationAccepted, setInvitationAccepted] = useState(0);
  const [acceptanceRate, setAcceptanceRate] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        if (selectedCampaign === "") return;
        const { data } = await axios.post("/campaign/getCampaignData", {
          campaignId: selectedCampaign,
        });
        // console.log(data);
        dispatch(setSingleCampaign(data.campaign));
        setTotalProspects(data.campaign.prospects.length);
        const accepted = data.campaign.prospects.filter(
          (prospect: any) => prospect.connected === "ACCEPTED"
        );
        setInvitationAccepted(accepted.length);
        setAcceptanceRate(
          Math.round((accepted.length / data.campaign.prospects.length) * 100)
        );
      } catch (error) {
        toast.error("Error fetching campaign data");
        console.log(error);
      }
    })();
  }, [selectedCampaign]);

  return (
    <StatsCardReusable
      info="Number of accepted invitations, out of the total sent with Leadseeder"
      icon={<HiMiniLink size={25} />}
      statLabel="Accepted Invitations"
      statNumber={invitationAccepted}
      progressValue={acceptanceRate}
      percentageLabel="Acceptance Rate"
      className="w-1/2"
    />
  );
};

export default Progresscard;
