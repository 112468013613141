import { createSlice } from "@reduxjs/toolkit";
import { createTemplate, deleteTemplate } from "../features/ownerActions";

const templateSlice = createSlice({
  name: "template",
  initialState: {
    templates: [],
    selectedTemplate: {
      ownerId: localStorage.getItem("owner")
        ? JSON.parse(localStorage.getItem("owner")!)._id
        : "",
      templateType: "EMAIL",
      templateName: "Invitation",
      content: {
        subject: "",
        body: "",
      },
    },
    loading: false,
    error: "",
    success: false,
    newTemplateData: {
      ownerId: localStorage.getItem("owner")
        ? JSON.parse(localStorage.getItem("owner")!)._id
        : "",
      templateType: "EMAIL",
      templateName: "Invitation",
      content: {
        subject: "",
        body: "",
      },
    },
  },
  reducers: {
    setTemplates: (state, action) => {
      state.templates = action.payload;
    },
    setNewTemplateData: (state, action) => {
      state.newTemplateData = action.payload;
    },
    setSelectedTemplate: (state, action) => {
      state.selectedTemplate = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createTemplate.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createTemplate.rejected, (state, action) => {
        // @ts-ignore
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(createTemplate.fulfilled, (state, action) => {
        // @ts-ignore
        state.templates.push(action.payload);
        state.loading = false;
        state.success = true;
      });

    builder
      .addCase(deleteTemplate.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteTemplate.rejected, (state, action) => {
        // @ts-ignore
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(deleteTemplate.fulfilled, (state, action) => {
        state.templates = action.payload;
        state.loading = false;
        state.success = true;
      });
  },
});

export const { setTemplates, setNewTemplateData, setSelectedTemplate } =
  templateSlice.actions;
export default templateSlice.reducer;
