import React, { useCallback, useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { FaCheck, FaPause, FaPencil, FaPlay, FaPlus } from "react-icons/fa6";
import { IoIosMore } from "react-icons/io";
import { RiSeedlingFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import CampaignModal from "./CampaignModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState, ownerSelector } from "../../store/store";
import {
  setCampaignId,
  setCampaigns,
  setNewCampaignData,
} from "../../store/slices/ownerSlice";
import { fetchCampaigns } from "../../utils";
import { FaArchive } from "react-icons/fa";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import {
  launchCampaign,
  updateCampaign,
} from "../../store/features/ownerActions";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
import { MdDelete, MdInfoOutline, MdPeople } from "react-icons/md";
import { useWindowResize } from "../../hooks/useWindowResize";
import { Campaign } from "../../types";
import { Popover } from "antd";

type Props = {};

const campaignStates = [
  {
    id: 1,
    name: "Active",
    path: "/campaigns/list/running",
    icon: <FaPlay size={20} />,
  },
  {
    id: 2,
    name: "Paused",
    path: "/campaigns/list/paused",
    icon: <FaPause size={20} />,
  },
  {
    id: 3,
    name: "Draft",
    path: "/campaigns/list/draft",
    icon: <FaPencil size={20} />,
  },
  {
    id: 4,
    name: "Archived",
    path: "/campaigns/list/archived",
    icon: <FaArchive size={20} />,
  },
];

const CampaignMain = (props: Props) => {
  const { owner, newCampaignData } = useSelector(ownerSelector);
  const [emptyTabs, setEmptyTabs] = useState<string[]>([]);
  const path = window.location.pathname;
  const [tabName, setTabName] = useState(
    path.split("/")[3] === "running"
      ? "active"
      : path.split("/")[3] === "archived"
      ? "completed"
      : path.split("/")[3] === "paused"
      ? "paused"
      : "draft"
  );
  const [campaignsUpdated, setCampaignsUpdated] = useState(false);
  const [showCampaignModal, setShowCampaignModal] = useState(false);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const navigate = useNavigate();
  const dispatch: ThunkDispatch<RootState, undefined, Action> = useDispatch();
  const { campaigns, campaignId } = useSelector(ownerSelector);
  const [filteredCampaigns, setFilteredCampaigns] =
    useState<Campaign[]>(campaigns);
  const { height } = useWindowResize();
  const [gapFromTop, setGapFromTop] = useState("0px");
  useEffect(() => {
    // if (newCampaignData) {
    //   setCampaignsUpdated(!campaignsUpdated);
    //   dispatch(setNewCampaignData(null));
    // }
    console.log("Campaigns updated");

    handleCampaignFetch();
  }, [campaignsUpdated]);

  const handleCampaignFetch = useCallback(async () => {
    try {
      const fetchedCampaigns = await fetchCampaigns(owner?._id as string);
      // console.log(fetchedCampaigns);
      if (fetchedCampaigns.length > 0) {
        dispatch(setCampaigns(fetchedCampaigns));
        setFilteredCampaigns(fetchedCampaigns);
        // setFilteredCampaigns(filteredCampaigns);

        setEmptyTabs([
          fetchedCampaigns.filter(
            (campaign: any) => campaign.campaignStatus === "ACTIVE"
          ).length === 0
            ? "active"
            : "",
          fetchedCampaigns.filter(
            (campaign: any) => campaign.campaignStatus === "PAUSED"
          ).length === 0
            ? "paused"
            : "",
          fetchedCampaigns.filter(
            (campaign: any) => campaign.campaignStatus === "DRAFT"
          ).length === 0
            ? "draft"
            : "",
          fetchedCampaigns.filter(
            (campaign: any) => campaign.campaignStatus === "ARCHIVED"
          ).length === 0
            ? "archived"
            : "",
          fetchedCampaigns.filter(
            (campaign: any) => campaign.campaignStatus === "COMPLETED"
          ).length === 0
            ? "completed"
            : "",
        ]);
      } else {
        setEmptyTabs(["active", "paused", "draft", "archived", "completed"]);
        dispatch(setCampaigns([]));
        toast.error("No campaigns found");
      }
    } catch (error) {
      console.log(error);
    }
  }, [campaignsUpdated]);

  useEffect(() => {
    const table = document.getElementById("campaigns");
    const positionY = table?.getBoundingClientRect().top;
    setGapFromTop(String(positionY));
    table?.style.setProperty("max-height", `calc(100vh - ${positionY}px)`);
  }, [height]);

  const handleCampaignLaunch = async () => {
    const loader = toast.loading("Launching campaign...");
    try {
      // console.log(campaignId);
      const res = await dispatch(
        launchCampaign({ ownerId: owner?._id, campaignId })
      );

      // console.log(res);

      if (res.meta.requestStatus === "fulfilled") {
        const updatedCampaigns = await fetchCampaigns(owner?._id as string);
        dispatch(setCampaigns(updatedCampaigns));
        setCampaignsUpdated(!campaignsUpdated);
        setShowMoreOptions(false);
        toast.dismiss(loader);
        toast.success("Campaign launched successfully");
        navigate("/campaigns/list/running");
      }
      if (res.meta.requestStatus === "rejected") {
        toast.dismiss(loader);
        if (res.payload === "Request failed with status code 400") {
          toast.error("You can only run one campaign at a time");
        } else {
          toast.error("Failed to launch campaign");
        }
        setShowMoreOptions(false);
      }
    } catch (error) {
      console.log(error);
      toast.dismiss(loader);
      toast.error("Failed to launch campaign");
    }
  };

  const handleUpdateCampaign = async (campaign: any, status: string) => {
    try {
      const reqdata = {
        ...campaign,
        campaignStatus: status,
        campaignId: campaign._id,
      };
      // console.log(reqdata);
      const res = await dispatch(updateCampaign(reqdata));
      if (res.meta.requestStatus === "fulfilled") {
        toast.success("Campaign updated successfully");
        setCampaignsUpdated(!campaignsUpdated);
        setShowMoreOptions(false);
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to update campaign");
    }
  };

  const handleCampaignDelete = async (campaign: any) => {
    try {
      const res = await axios.post(`/campaign/deleteCampaign`, {
        campaignId: campaign._id,
      });

      if (res.status === 200) {
        const updatedCampaigns = await fetchCampaigns(owner?._id as string);
        dispatch(setCampaigns(updatedCampaigns));
        setCampaignsUpdated(!campaignsUpdated);
        toast.success("Campaign deleted successfully");
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to delete campaign");
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    const filtered = campaigns.filter((campaign) =>
      campaign.campaignName.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredCampaigns(filtered);
  };
  return (
    <section className="flex w-full flex-col gap-8">
      <div className="flex items-center gap-1">
        <h1 className="text-xl font-semibold">My campaigns</h1>
        <Popover
          placement="right"
          content={<p className="text-xs">View and manage your campaigns</p>}
        >
          <MdInfoOutline size={16} />
        </Popover>
      </div>
      <div className=" flex justify-between pb-4 border-b-2 border-b-mainColor/30">
        <div className="flex  gap-1 w-1/2">
          {campaignStates.map((state) => (
            <div
              onClick={() => {
                setTabName(state.name.toLowerCase());
                navigate(state.path);
                setShowMoreOptions(false);
              }}
              key={state.id}
              className={`flex text-sm gap-2 font-semibold items-center p-1 px-2 rounded-md cursor-pointer ${
                tabName === state.name.toLowerCase()
                  ? "bg-mainColor text-white"
                  : ""
              }`}
            >
              {state.icon}
              <p>{state.name}</p>
              <p className="p-1 px-2 bg-mainColor/20 rounded-xl">
                {
                  campaigns.filter(
                    (campaign: any) =>
                      campaign.campaignStatus === state.name.toUpperCase()
                  ).length
                }
              </p>
            </div>
          ))}
        </div>
        <div className="">
          <div className="flex justify-end text-sm gap-2 ">
            <div className="flex items-center  gap-2 border-2 bg-white border-mainColor rounded-md p-1 w-[40%] text-xs">
              <CiSearch size={25} />
              <input
                onChange={handleSearch}
                className="outline-none border-none w-full"
                type="text"
                placeholder="Search"
              />
            </div>
            <button
              onClick={() => navigate("/campaigns/create")}
              className="bg-mainColor flex items-center shadow-md gap-2 font-semibold text-white p-2 px-4 rounded-md"
            >
              <FaPlus className="text-white" size={15} />
              <p>Create Campaign</p>
            </button>
          </div>
        </div>
      </div>
      <div
        id="campaigns"
        className="flex pb-16 overflow-y-auto min-h-[60vh] flex-wrap slimScrollbar w-full gap-4"
      >
        <div className="flex flex-wrap gap-2 h-full justify-start items-start">
          {filteredCampaigns.map(
            (campaign: Campaign) =>
              campaign.campaignStatus.toLowerCase() === tabName &&
              (console.log(campaign.campaignStatus.toLocaleLowerCase()),
              (
                <div
                  key={campaign._id}
                  className="flex gap-4 p-4 cursor-pointer text-white bg-mainColor/70 rounded-md w-fit h-fit"
                >
                  <div className="flex justify-center items-center gap-4 ">
                    <p
                      onClick={() => {
                        dispatch(setCampaignId(campaign._id));
                        setShowCampaignModal(true);
                      }}
                      className="p-2 px-4 bg-mainColor rounded-full"
                    >
                      {campaign.campaignName[0].toLocaleUpperCase()}
                    </p>
                    <div
                      onClick={() => {
                        dispatch(setCampaignId(campaign._id));
                        setShowCampaignModal(true);
                      }}
                      className="text-sm w-fit font-semibold"
                    >
                      <p>{campaign.campaignName}</p>

                      <div className="flex w-fit text-sm gap-1 bg-white p-1 rounded-lg text-mainColor  items-center">
                        <span>
                          <MdPeople size={15} />
                        </span>
                        <p className="text-xs">{campaign.prospects.length}</p>
                      </div>
                    </div>
                    <div className="relative ">
                      <span
                        className="relative"
                        onClick={() => {
                          setShowMoreOptions(!showMoreOptions);
                          dispatch(setCampaignId(campaign._id));
                        }}
                      >
                        <IoIosMore size={25} />
                      </span>

                      {showMoreOptions && campaign._id === campaignId && (
                        <div className="absolute text-gray-900 -left-8 top-8 bg-white p-4 rounded-md shadow-md flex flex-col gap-2 z-50">
                          {campaign.campaignStatus.toLowerCase() ===
                            "draft" && (
                            <div className="flex gap-2 flex-col ">
                              <div
                                onClick={() => {
                                  handleUpdateCampaign(campaign, "ARCHIVED");
                                }}
                                className="flex p-2 rounded-md hover:bg-primary gap-2 items-center text-sm"
                              >
                                <FaArchive />
                                <p>Archive</p>
                              </div>

                              <div
                                onClick={handleCampaignLaunch}
                                className="flex p-2 rounded-md hover:bg-primary gap-2 items-center text-sm"
                              >
                                <FaPlay />
                                <p>Run</p>
                              </div>
                            </div>
                          )}

                          {/* <div className="flex p-2 rounded-md hover:bg-primary gap-2 items-center text-sm">
                          <FaPencil />
                          <p>Edit</p>
                        </div> */}
                          {campaign.campaignStatus.toLowerCase() ===
                            "active" && (
                            <div>
                              <div
                                onClick={() =>
                                  handleUpdateCampaign(campaign, "PAUSED")
                                }
                                className="flex p-2 rounded-md hover:bg-primary gap-2 items-center text-sm"
                              >
                                <FaPause />
                                <p>Pause</p>
                              </div>
                              <div
                                onClick={() => {
                                  handleUpdateCampaign(campaign, "ARCHIVED");
                                }}
                                className="flex p-2 rounded-md hover:bg-primary gap-2 items-center text-sm"
                              >
                                <FaArchive />
                                <p>Archive</p>
                              </div>
                            </div>
                          )}
                          {campaign.campaignStatus.toLowerCase() ===
                            "paused" && (
                            <div>
                              <div
                                onClick={() =>
                                  handleUpdateCampaign(campaign, "ACTIVE")
                                }
                                className="flex p-2 rounded-md hover:bg-primary gap-2 items-center text-xs"
                              >
                                <FaPlay />
                                <p>Run</p>
                              </div>
                              <div
                                onClick={() => {
                                  handleUpdateCampaign(campaign, "ARCHIVED");
                                }}
                                className="flex p-2 rounded-md hover:bg-primary gap-2 items-center text-xs"
                              >
                                <FaArchive />
                                <p>Archive</p>
                              </div>
                            </div>
                          )}

                          {campaign.campaignStatus.toLowerCase() ===
                            "archived" && (
                            <div>
                              <div
                                onClick={() => handleCampaignDelete(campaign)}
                                className="flex p-2 rounded-md hover:bg-primary gap-2 items-center text-sm"
                              >
                                <MdDelete />
                                <p>Delete</p>
                              </div>
                            </div>
                          )}
                          {/* {campaign.campaignStatus.toLowerCase() ===
                            "completed" && (
                            <div>
                              <div
                                onClick={() => handleCampaignDelete(campaign)}
                                className="flex p-2 rounded-md hover:bg-primary gap-2 items-center text-sm"
                              >
                                <MdDelete />
                                <p>Delete</p>
                              </div>
                            </div>
                          )} */}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))
          )}
        </div>

        {emptyTabs.includes(tabName) && (
          <div className="flex justify-center h-[50vh] items-center w-full my-auto flex-col gap-4">
            <span className="text-mainColor">
              <RiSeedlingFill size={70} />
            </span>
            <p className="text-xl font-semibold text-gray-500">
              Start a campaign to grow your network
            </p>
            <button
              onClick={() => navigate("/campaigns/create")}
              className="bg-mainColor flex items-center shadow-md gap-2 font-semibold text-white p-2 px-4 rounded-md text-sm"
            >
              <FaPlus className="text-white" size={15} />
              <p>Start Campaign</p>
            </button>
          </div>
        )}
      </div>
      {showCampaignModal && (
        <CampaignModal onClose={() => setShowCampaignModal(false)} />
      )}
    </section>
  );
};

export default CampaignMain;
