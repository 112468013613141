import React, { useEffect, useState } from "react";
import { IoMdEye } from "react-icons/io";
import { MdInfo, MdInfoOutline, MdLink, MdMessage } from "react-icons/md";
import { ownerSelector, RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { fetchOwner } from "../../utils";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { updateDailyQuota } from "../../store/features/ownerActions";
import { toast } from "react-toastify";
import { Popover } from "antd";
import { setOwner } from "../../store/slices/ownerSlice";

type Props = {};

const quotas = [
  {
    title: "Invitations",
    key: "invitations",
    icon: <MdLink size={20} />,
  },
  {
    title: "Messages",
    key: "messages",
    icon: <MdMessage size={20} />,
  },

  {
    title: "Profile Visits",
    key: "visits",
    icon: <IoMdEye size={20} />,
  },
];

const QuotaSettings = (props: Props) => {
  const { owner, loading } = useSelector(ownerSelector);
  const [dailyQuota, setDailyQuota] = useState(owner?.dailyQuota!);
  const [dailyUsage, setDailyUsage] = useState(owner?.dailyUsage!);
  const dispatch: ThunkDispatch<RootState, undefined, Action> = useDispatch();

  useEffect(() => {
    (async () => {
      const updatedOwner = await fetchOwner(owner?._id as string);
      setDailyQuota(updatedOwner.dailyQuota);
      setDailyUsage(updatedOwner.dailyUsage);
    })();
  }, [owner?._id]);

  const handleQuotaUpdate = async (type: string) => {
    try {
      if (type === "RESET") {
        const reqData = {
          ownerId: owner?._id as string,
          dailyQuota: {
            invitations: 50,
            messages: 50,
            visits: 50,
            follows: 50,
          },
        };

        const res = await dispatch(updateDailyQuota(reqData));

        if (res.payload.success) {
          setDailyQuota(res.payload.data.dailyQuota);

          toast.success("Daily quota updated successfully");
        }
      } else {
        if (dailyQuota.invitations > 100) {
          toast.error("You can send max 100 invitations per day");
          return;
        }
        if (dailyQuota.messages > 100) {
          toast.error("You can send max 100 messages per day");
          return;
        }
        if (dailyQuota.visits > 200) {
          toast.error("You can visits max 200 profiles per day");
          return;
        }
        const reqData = {
          ownerId: owner?._id as string,
          dailyQuota: dailyQuota,
        };

        const res = await dispatch(updateDailyQuota(reqData));

        if (res.payload.success) {
          setDailyQuota(res.payload.data.dailyQuota);
          toast.success("Daily quota updated successfully");
          dispatch(setOwner(res.payload.data));
        } else {
          toast.error("Failed to update daily quota");
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to update daily quota");
    }
  };
  return (
    <section className="flex gap-6 flex-col">
      <div>
        <h1 className="text-lg font-semibold">Edit your max daily quota</h1>
        <small>
          Specific limit: Linkedin allows only 200 invitations max. per week
        </small>
      </div>

      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2">
          <h2 className="font-semibold">Linkedin usage </h2>
          {/* <p>
            <Popover
              placement="right"
              content={
                <p className="text-xs">
                  Updated daily limit will be effective from the next day.
                </p>
              }
            >
              <MdInfoOutline size={15} />
            </Popover>
          </p> */}
          {owner?.usageError && (
            <p className="text-xs text-red-800 bg-red-200 p-1 rounded-lg">
              {owner?.usageError}
            </p>
          )}
        </div>
        <div className="flex gap-4">
          <div
            key={quotas[0].key}
            className="flex gap-4 justify-between items-center p-4 bg-white rounded-lg text-xs"
          >
            <span className="text-white rounded-full p-2 bg-mainColor">
              {quotas[0].icon}
            </span>
            <div className="flex flex-col gap-2">
              <p className="font-semibold uppercase">{quotas[0].title}</p>
              <div className="flex justify-center gap-2 items-center">
                <p>
                  Used <b>{dailyUsage && dailyUsage["invitations"]}</b> of
                </p>

                <input
                  min={1}
                  max={100}
                  type="number"
                  value={dailyQuota && dailyQuota["invitations"]}
                  onChange={(e) => {
                    setDailyQuota({
                      ...dailyQuota,
                      invitations: parseInt(e.target.value),
                    });
                  }}
                  className="border-2 w-12 appearance-none border-mainColor rounded-lg p-1 text-center"
                ></input>
                <p>/day</p>
              </div>
            </div>
          </div>
          <div
            key={quotas[1].key}
            className="flex gap-4 justify-between items-center p-4 bg-white rounded-lg text-xs"
          >
            <span className="text-white rounded-full p-2 bg-mainColor">
              {quotas[1].icon}
            </span>
            <div className="flex flex-col gap-2">
              <p className="font-semibold uppercase">{quotas[1].title}</p>
              <div className="flex justify-center gap-2 items-center">
                <p>
                  Used <b>{dailyUsage && dailyUsage["messages"]}</b> of
                </p>
                <input
                  type="number"
                  min={1}
                  max={100}
                  value={dailyQuota && dailyQuota["messages"]}
                  onChange={(e) => {
                    setDailyQuota({
                      ...dailyQuota,
                      messages: parseInt(e.target.value),
                    });
                  }}
                  className="border-2 w-12 appearance-none border-mainColor rounded-lg p-1 text-center"
                ></input>
                <p>/day</p>
              </div>
            </div>
          </div>
          <div
            key={quotas[2].key}
            className="flex gap-4 justify-between items-center p-4 bg-white rounded-lg text-xs"
          >
            <span className="text-white rounded-full p-2 bg-mainColor">
              {quotas[2].icon}
            </span>
            <div className="flex flex-col gap-2">
              <p className="font-semibold uppercase">{quotas[2].title}</p>
              <div className="flex justify-center gap-2 items-center">
                <p>
                  Used <b>{dailyUsage && dailyUsage["visits"]}</b> of
                </p>
                <input
                  type="number"
                  min={1}
                  max={100}
                  value={dailyQuota && dailyQuota["visits"]}
                  onChange={(e) => {
                    setDailyQuota({
                      ...dailyQuota,
                      visits: parseInt(e.target.value),
                    });
                  }}
                  className="border-2 w-12 border-mainColor rounded-lg p-1 text-center"
                ></input>
                <p>/day</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-4 text-xs">
          <button
            disabled={loading}
            onClick={() => handleQuotaUpdate("UPDATE")}
            className="bg-mainColor flex items-center justify-center gap-2 text-white p-2 text-xs rounded-md"
          >
            Update
          </button>
          <button
            disabled={loading}
            onClick={() => handleQuotaUpdate("RESET")}
            className="bg-mainColor flex items-center justify-center gap-2 text-white p-2 text-xs rounded-md"
          >
            Restore Default
          </button>
        </div>
      </div>
    </section>
  );
};

export default QuotaSettings;
