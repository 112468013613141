import React, { useEffect, useRef } from "react";
import {
  MdCake,
  MdCancel,
  MdEmail,
  MdLink,
  MdLocationOn,
  MdPhone,
} from "react-icons/md";
import { useSelector } from "react-redux";
import { prospectsSelector } from "../../store/store";
import { RxAvatar } from "react-icons/rx";
import { Link } from "react-router-dom";
import { IoIosLeaf, IoMdEye } from "react-icons/io";

type Props = {
  onClose: () => void;
  selfRef?: React.RefObject<HTMLDivElement>;
};

const ProfileModal = (props: Props) => {
  const { currentProspect } = useSelector(prospectsSelector);
  const modalSelfRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        modalSelfRef.current &&
        !modalSelfRef.current.contains(e.target as Node)
      ) {
        props.onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props]);
  return (
    <div className="absolute top-0 left-0 z-50 w-full h-screen backdrop-blur-sm bg-black/20">
      <div>
        <div
          ref={modalSelfRef}
          className="bg-white w-[80%] text-xs max-h-[90%] absolute top-1/2 left-1/2 transform  -translate-x-1/2 -translate-y-1/2 rounded-lg p-6"
        >
          <span
            className="text-mainColor absolute right-4 cursor-pointer"
            onClick={props.onClose}
          >
            <MdCancel size={25} />
          </span>
          <div className="flex h-full w-full flex-col gap-4">
            <h1 className="text-xl font-semibold">Prospect's Details</h1>
            <div className="flex h-full w-full gap-4 ">
              <div className="w-[30%] h-full flex flex-col gap-8 border-r-2 px-4 border-mainColor/30 ">
                <div className="bg-primary flex flex-col justify-center items-center gap-6 shadow-lg p-4 rounded-lg w-full">
                  {currentProspect.image_url ? (
                    <img
                      src={currentProspect.image_url}
                      alt=""
                      className="w-24 h-24 rounded-full"
                    />
                  ) : (
                    <div className="w-24 h-24 bg-mainColor/20 rounded-full flex justify-center items-center">
                      <RxAvatar className="w-24 h-24" />
                    </div>
                  )}
                  <div className="flex max-w-[90%] flex-col gap-2 justify-center items-center overflow-hidden">
                    <Link
                      onClick={(e) => e.stopPropagation()}
                      to={
                        "https://www.linkedin.com/in/" +
                        currentProspect.linkedinId
                      }
                      target="_blank"
                    >
                      <p className=" text-sm font-semibold">
                        {currentProspect.full_name}
                      </p>
                    </Link>
                    <p className="text-xs text-wrap  whitespace-pre">
                      {currentProspect.headline}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col gap-2 ">
                  <h1 className="text-xl font-semibold">Prospect Details</h1>
                  <div className="bg-primary shadow-lg rounded-lg flex flex-col w-full p-4">
                    <span className="flex w-full items-center justify-between gap-6 p-2">
                      <p className="flex justify-center items-center gap-2">
                        <MdEmail size={20} className="text-mainColor" /> Email
                      </p>
                      <p className=" text-xs">
                        {currentProspect.contactInfo?.emailAddress
                          ? currentProspect.contactInfo?.emailAddress
                          : "N/A"}
                      </p>
                    </span>
                    <span className="flex w-full items-center justify-between p-2">
                      <p className="flex justify-center items-center gap-2">
                        <MdPhone size={20} className="text-mainColor" /> Phone
                      </p>
                      <p>
                        {currentProspect.contactInfo?.phoneNumbers
                          ? currentProspect.contactInfo?.phoneNumbers[0]?.number
                          : "N/A"}
                      </p>
                    </span>

                    <span className="flex w-full items-center gap-6 justify-between p-2">
                      <p className="flex justify-center items-center gap-2">
                        <MdLocationOn size={20} className="text-mainColor" />{" "}
                        Location
                      </p>
                      <p className=" text-right text-wrap text-xs ml-auto">
                        {currentProspect.locality
                          ? currentProspect.locality
                          : "N/A"}
                      </p>
                    </span>
                    <span className="flex w-full items-center justify-between p-2">
                      <p className="flex justify-center items-center gap-2">
                        <MdCake size={20} className="text-mainColor" />{" "}
                        <p>Birthday</p>
                      </p>
                      <p>
                        {currentProspect.contactInfo?.birthDateOn
                          ? `${currentProspect.contactInfo?.birthDateOn?.day}/${currentProspect.contactInfo?.birthDateOn?.month}`
                          : "N/A"}
                      </p>
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-4 w-full">
                <h1 className="text-xl font-semibold">Activity</h1>
                {currentProspect.connected === "ACCEPTED" && (
                  <div className="bg-primary w-full shadow-lg rounded-lg flex flex-col p-4">
                    <div className="  flex items-center justify-between">
                      <div className="flex gap-2">
                        <MdLink size={20} className="text-mainColor" />
                        <span className="font-semibold">Connected</span>
                      </div>
                      <small>
                        You are connected with {currentProspect.full_name}
                      </small>
                    </div>
                  </div>
                )}
                {currentProspect.connected === "PENDING" && (
                  <div className="bg-primary w-full shadow-lg rounded-lg flex flex-col p-4">
                    <div className="  flex items-center justify-between">
                      <div className="flex gap-2">
                        <MdLink size={20} className="text-mainColor" />
                        <span className="font-semibold">Request Sent</span>
                      </div>
                      <small>
                        You sent connection request to{" "}
                        <span className="font-semibold">
                          {currentProspect.full_name}
                        </span>{" "}
                        using Leadseeder
                      </small>
                    </div>
                  </div>
                )}
                {currentProspect.messageSent && (
                  <div className="bg-primary w-full shadow-lg rounded-lg flex flex-col p-4">
                    <div className="  flex items-center justify-between">
                      <div className="flex gap-2">
                        <MdLink size={20} className="text-mainColor" />
                        <span className="font-semibold">Message Sent</span>
                      </div>
                      <small>
                        You sent messages to{" "}
                        <span className="font-semibold">
                          {currentProspect.full_name}
                        </span>{" "}
                        using Leadseeder
                      </small>
                    </div>
                  </div>
                )}
                {currentProspect.profileVisited ? (
                  <div className="bg-primary w-full shadow-lg rounded-lg flex flex-col p-4">
                    <div className="  flex items-center justify-between">
                      <div className="flex gap-2">
                        <IoMdEye size={20} className="text-mainColor" />
                        <span className="font-semibold">Profile Visited</span>
                      </div>
                      <small>You visited this profile using LeadSeeder</small>
                    </div>
                  </div>
                ) : (
                  currentProspect.connected === "NOT_STARTED" && (
                    <div className="h-full flex flex-col justify-center items-center bg-white my-2 w-full rounded-xl">
                      <IoIosLeaf size={100} className="text-mainColor" />
                      <p className="text-lg font-semibold">
                        Oh snap! Nothing to see.
                      </p>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileModal;
