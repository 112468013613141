import { createSlice } from "@reduxjs/toolkit";
import { Prospect } from "../../types";

export type ProspectsState = {
  listName: string;
  description: string;
  inCampaign: boolean;
  prospects: Prospect[];
  prospectId: string;
  currentProspect: Prospect;
  selectedProspects: string[];
  prospectsCount: number;
};

const initialState: ProspectsState = {
  listName: "",
  description: "",
  inCampaign: false,
  prospects: [],
  prospectId: "",
  currentProspect: {} as Prospect,
  selectedProspects: [],
  prospectsCount: 0,
};

const prospectsSlice = createSlice({
  name: "prospects",
  initialState,
  reducers: {
    setProspects: (state, action) => {
      state.prospects = action.payload;
    },
    setProspectId: (state, action) => {
      state.prospectId = action.payload;
    },
    setListName: (state, action) => {
      state.listName = action.payload;
    },
    setListDescription: (state, action) => {
      state.description = action.payload;
    },
    setCurrentProspect: (state, action) => {
      state.currentProspect = action.payload;
    },
    setSelectedProspects: (state, action) => {
      state.selectedProspects = action.payload;
    },
    setInCampaign: (state, action) => {
      state.inCampaign = action.payload;
    },
    setProspectsCount: (state, action) => {
      state.prospectsCount = action.payload;
    },
  },
});

export const {
  setProspects,
  setProspectId,
  setListName,
  setCurrentProspect,
  setSelectedProspects,
  setInCampaign,
  setListDescription,
  setProspectsCount,
} = prospectsSlice.actions;

export default prospectsSlice.reducer;
