import axios from "./axios";

export const fetchOwner = async (ownerId: string) => {
  try {
    const response = await axios.post("/owner/getOwner", {
      ownerId,
    });

    return response.data;
  } catch (error) {
    throw new Error("Error fetching owner");
  }
};

export const fetchLists = async (ownerId: string, workspaceId: string) => {
  try {
    const response = await axios.post("/prospect/getProspectList", {
      ownerId,
      workspaceId,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error("Error fetching lists");
  }
};

export const fetchTemplates = async (ownerId: string) => {
  try {
    const response = await axios.post("/template/getTemplates", {
      ownerId,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error("Error fetching templates");
  }
};

export const fetchCampaigns = async (ownerId: string) => {
  try {
    const response = await axios.post("/campaign/getCampaigns", {
      ownerId,
    });

    return response.data.campaigns;
  } catch (error) {
    throw new Error("Error fetching campaigns");
  }
};

export const fetchTags = async (ownerId: string) => {
  try {
    const response = await axios.post("/tags/getTags", {
      ownerId,
    });

    return response.data;
  } catch (error) {
    throw new Error("Error fetching tags");
  }
};

export const fetchProspects = async (
  ownerId: string,
  listId: string,
  page: number,
  limit: number
) => {
  try {
    const response = await axios.post(
      `/prospect/getProspectsOfList?page=${page}&limit=${limit}`,
      {
        ownerId,
        listId,
      }
    );

    return response.data;
  } catch (error) {
    throw new Error("Error fetching prospects");
  }
};

export const fetchOwnerLinkedin = async (
  ownerId: string,
  profileUrn: string
) => {
  try {
    const response = await axios.post("/linkedin/profileView", {
      ownerId,
      profileUrn,
    });

    return response.data;
  } catch (error) {
    throw new Error("Error fetching owner linkedin");
  }
};

export const fetchOwnerConnections = async (ownerId: string) => {
  try {
    const response = await axios.post("/linkedin/getAllConnections", {
      ownerId,
    });

    return response.data.connections;
  } catch (error) {
    console.log(error);
    throw new Error("Error fetching owner connections");
  }
};

export const fetchAllRequests = async (ownerId: string, type: string) => {
  try {
    const response = await axios.post("/linkedin/getAllRequests", {
      ownerId,
      type,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error("Error fetching requests");
  }
};

export const deleteAllRequests = async (ownerId: string) => {
  try {
    const response = await axios.post("/linkedin/deleteAllRequests", {
      ownerId,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error("Error deleting requests");
  }
};

export const fetchTeamMembers = async (
  ownerId: string,
  workspaceId: string
) => {
  try {
    const response = await axios.post("/teams/getTeamMembers", {
      ownerId,
      workspaceId,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error("Error fetching team members");
  }
};
