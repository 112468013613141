import React from "react";
import { Link } from "react-router-dom";

type Props = {};

const Campaignbar = (props: Props) => {
  const path = window.location.pathname;
  return (
    <div className="w-[20%] h-full border-r-2 border-mainColor/30">
      <ul className="mt-4 whitespace-nowrap gap-4 flex flex-col justify-center items-center px-2">
        <Link
          to="/campaigns/list/running"
          className={`${
            path.includes("/campaigns/list")
              ? "bg-mainColor/10 text-gray-900 border-l-4 border-mainColor font-semibold"
              : "border-l-4 border-primary bg-white/80"
          } flex  rounded-lg  p-3  w-full  justify-start gap-4 items-center hover:bg-mainColor/20  text-xs transition-all ease-in-out duration-100`}
        >
          Campaign List
        </Link>
        <Link
          to="/campaigns/template"
          className={`${
            path === "/campaigns/template"
              ? "bg-mainColor/10 text-gray-900 border-l-4 border-mainColor font-semibold"
              : "border-l-4 border-primary bg-white/80"
          } flex  rounded-lg  p-3 justify-start gap-4 items-center hover:bg-mainColor/20  w-full transition-all ease-in-out duration-100 text-xs`}
        >
          Message Template
        </Link>
      </ul>
    </div>
  );
};

export default Campaignbar;
