function setIntercom() {
    let user = JSON.parse(localStorage.getItem("owner"))
  
    if (!user) return
    console.log("setting up intercom for ...", user.email)
    window.Intercom('boot', {
      api_base: "https://api-iam.intercom.io",
      app_id: "d5xaul0z",
      name: user?.name, // Full name
      email: user?.chrome_email_data[user.chrome_email_data.length - 1], // Email address
      user_id: user?._id, // current_user_i d
      created_at: user.createdAt // Signup date as a Unix timestamp
    });
  
  }
  
  module.exports = { setIntercom };