import { Input, Popover } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ownerSelector, RootState } from "../../store/store";
import { toast } from "react-toastify";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import {
  addTagToProspect,
  createTag,
  removeTagFromProspect,
} from "../../store/features/ownerActions";
import { setListId, setTags } from "../../store/slices/ownerSlice";
import { fetchProspects } from "../../utils";
import { setProspects } from "../../store/slices/prospectsSlice";

type TagsProps = {
  prospectId: string;
  tagModalSelfRef: React.RefObject<HTMLDivElement>;
  listId: string;
  onClose: () => void;
  onRemoveTag?: () => void;
};

const Tags = ({ prospectId, tagModalSelfRef, listId, onClose }: TagsProps) => {
  const { tags, owner } = useSelector(ownerSelector);
  const [filteredTags, setFilteredTags] = useState(tags);
  const [tagName, setTagName] = useState("");
  const dispatch: ThunkDispatch<RootState, undefined, Action> = useDispatch();

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === "") {
      setFilteredTags(tags);
    } else {
      const filtered = tags.filter((tag) =>
        tag.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredTags(filtered);
    }
  };

  const handleAddTagToProspect = async (tagId: string) => {
    try {
      const prospectIds = [prospectId];

      const res = await dispatch(addTagToProspect({ prospectIds, tagId }));
      if (res.meta.requestStatus === "fulfilled") {
        if (res.payload.success) {
          toast.success(res.payload.message);
          onClose();
          const list = await fetchProspects(
            owner?._id as string,
            listId,
            1,
            10
          );
          dispatch(setProspects(list.prospects));
        } else if (!res.payload.success) {
          toast.error(res.payload.message);
        }
      } else {
        toast.error("Error adding tag to prospect");
      }
    } catch (error: any) {
      console.log(error);
      toast.error("Error adding tag to prospect");
    }
  };

  const handleCreateTag = async () => {
    try {
      const res = await dispatch(
        createTag({ tagName, ownerId: owner?._id as string })
      );
      if (res.meta.requestStatus === "fulfilled") {
        if (res.payload.success) {
          dispatch(setTags([...tags, res.payload.tag]));
          toast.success(res.payload.message);
          onClose();
        } else if (!res.payload.success) {
          toast.error(res.payload.message);
        }
      } else {
        toast.error("Error creating tag");
      }
    } catch (error: any) {
      console.log(error);
      toast.error("Error creating tag");
    }
  };

  return (
    <div
      ref={tagModalSelfRef}
      className="bg-primary h-60 w-52 absolute right-0 top-6 z-50 shadow-xl p-4 rounded-lg gap-2 flex flex-col"
    >
      <h1 className="font-semibold text-xs">Tags</h1>
      <div>
        <Input
          value={tagName}
          onChange={(e) => {
            setTagName(e.target.value);
            handleSearch(e);
          }}
          className="text-xs"
          placeholder="Search or Create a tag"
        />
      </div>
      <div className="flex flex-wrap gap-2 justify-center items-center">
        {filteredTags?.length > 0 ? (
          filteredTags?.map((tag) => (
            <div key={tag._id} className="flex items-center gap-2">
              <p
                onClick={() => handleAddTagToProspect(tag._id)}
                style={{ backgroundColor: tag.color }}
                className={`text-xs py-0.5 px-2 cursor-pointer rounded-xl text-white`}
              >
                {tag.name}
              </p>
            </div>
          ))
        ) : (
          <div className="flex justify-center items-center flex-col gap-2">
            <p className="text-xs text-center opacity-45">No tags found</p>
            <button
              onClick={handleCreateTag}
              className="text-xs bg-mainColor text-white rounded-md p-1"
            >
              Create tag {tagName}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Tags;
