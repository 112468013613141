import { configureStore } from "@reduxjs/toolkit";
import ownerSlice from "./slices/ownerSlice";
import templateSlice from "./slices/templateSlice";
import prospectsSlice, { ProspectsState } from "./slices/prospectsSlice";
import dashboardSlice from "./slices/dashboardSlice";
import campaignSlice from "./slices/campaignSlice";

const store = configureStore({
  reducer: {
    owner: ownerSlice,
    template: templateSlice,
    prospects: prospectsSlice,
    dashboard: dashboardSlice,
    campaign: campaignSlice,
  },
  devTools: false,
});

export type RootState = ReturnType<typeof store.getState>;
export const ownerSelector = (state: RootState) => state.owner;
export const templateSelector = (state: any) => state.template;
export const prospectsSelector = (state: RootState) => state.prospects;
export const dashboardSelector = (state: any) => state.dashboard;
export const campaignSelector = (state: RootState) => state.campaign;

export default store;
