import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

export const login = createAsyncThunk(
  "owner/login",
  async (data: { email: string; password: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post("/owner/login", data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const createList = createAsyncThunk(
  "owner/addList",
  async (
    data: {
      ownerId: string;
      listName: string;
      description: string;
      workspaceId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post("/prospect/createProspectList", data);
      console.log(response.data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const updateList = createAsyncThunk(
  "owner/updateList",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post("/prospect/updateProspectList", data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const deleteList = createAsyncThunk(
  "owner/deleteList",
  async (data: { listId: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post("/prospect/deleteProspectList", data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const createCampaign = createAsyncThunk(
  "owner/createCampaign",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post("/campaign/createCampaign", data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);
export const saveCampaign = createAsyncThunk(
  "owner/saveCampaign",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post("/campaign/saveCampaign", data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const createTemplate = createAsyncThunk(
  "owner/createTemplate",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post("/template/createTemplate", data);

      console.log(response.data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteTemplate = createAsyncThunk(
  "owner/deleteTemplate",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post("/template/deleteTemplates", data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const updateOwner = createAsyncThunk(
  "owner/updateOwner",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post("/owner/updateOwner", data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const updateDailyQuota = createAsyncThunk(
  "owner/updateDailyQuota",
  async (
    data: {
      ownerId: string;
      dailyQuota: {
        invitations: number;
        messages: number;
        visits: number;
        follows: number;
      };
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post("/owner/updateDailyQuota", data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const launchCampaign = createAsyncThunk(
  "owner/launchCampaign",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post("/campaign/launchCampaign", data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const updateCampaign = createAsyncThunk(
  "owner/updateCampaign",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post("/campaign/editCampaign", data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const createTag = createAsyncThunk(
  "owner/createTag",
  async (
    data: {
      tagName: string;
      ownerId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post("/tags/createTag", data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const addTagToProspect = createAsyncThunk(
  "owner/addTagToProspect",
  async (
    data: {
      prospectIds: string[];
      tagId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post("/tags/addTag", data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const removeTagFromProspect = createAsyncThunk(
  "owner/removeTagFromProspect",
  async (
    data: {
      prospectIds: string[];
      tagId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post("/tags/removeTag", data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);
