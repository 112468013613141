import React, { useCallback, useEffect, useState } from "react";

import { MdGroup, MdHomeFilled, MdRocketLaunch } from "react-icons/md";

import { FaPeopleGroup } from "react-icons/fa6";

// import { Layout, Menu, Button, theme } from "antd";
import { Link, Navigate, Outlet, useNavigate } from "react-router-dom";

import Navbar from "./components/Navbars/Navbar";
import Sidebar from "./components/Navbars/Sidebar";
import { Slide, ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { RootState, ownerSelector } from "./store/store";
import axios from "./utils/axios";
import {
  setCampaigns,
  setCurrentWorkSpace,
  setListId,
  setLists,
  setOwner,
  setTags,
} from "./store/slices/ownerSlice";
import {
  fetchCampaigns,
  fetchLists,
  fetchOwner,
  fetchTags,
  fetchTemplates,
} from "./utils";
import { setTemplates } from "./store/slices/templateSlice";
import { setSelectedCampaign } from "./store/slices/campaignSlice";
import { LOGO_XL } from "./components/assets";
import {
  getAllConnections,
  getAllRequests,
  getProfileAnalytics,
  getQueuedActions,
} from "./store/features/dashboardActions";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { List } from "./types";

const DefaultLayout: React.FC = () => {
  // const [collapsed, setCollapsed] = useState(false);
  // const [pathName, setPathName] = useState(window.location.pathname);

  const { owner, currentWorkspace } = useSelector(ownerSelector);
  const dispatch: ThunkDispatch<RootState, undefined, Action> = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const loadInitialData = async () => {
      let tempOwner = JSON.parse(localStorage.getItem("owner")!);
      // console.log(tempOwner);
      if (tempOwner) {
        try {
          // console.log(owner);
          const updatedOwner = await fetchOwner(tempOwner?._id);
          // console.log(updatedOwner);
          // dispatch(setOwner(updatedOwner));
          dispatch(setCurrentWorkSpace(updatedOwner.workspaces[0]));
          // console.log(updatedOwner);
          const [lists, templates, campaigns, tags] = await Promise.all([
            fetchLists(updatedOwner._id, updatedOwner._id),
            fetchTemplates(updatedOwner._id),
            fetchCampaigns(updatedOwner._id),
            fetchTags(updatedOwner._id),
            dispatch(getAllConnections(owner?._id as string)),
            dispatch(
              getAllRequests({ ownerId: owner?._id as string, type: "SENT" })
            ),
            dispatch(getQueuedActions(owner?._id as string)),
            dispatch(getProfileAnalytics(owner?._id as string)),
          ]);
          // console.log(templates);
          dispatch(setTemplates(templates));
          dispatch(
            setLists(
              lists.filter((list: List) => list.listName !== "All Prospects")
            )
          );
          dispatch(setListId(lists[0]._id));
          dispatch(setCampaigns(campaigns));
          dispatch(setTags(tags));
          dispatch(setSelectedCampaign(campaigns[0]._id));
          dispatch(setOwner(updatedOwner));
          dispatch(setCurrentWorkSpace(updatedOwner.workspaces[0]));
        } catch (error: any) {
          toast.error(error);
        }
      }
    };

    loadInitialData();
  }, [owner?._id]);

  if (!owner) {
    return (
      <div className="flex gap-4 flex-col justify-center items-center h-screen">
        <img width={100} height={100} src={LOGO_XL} alt="" />
        <p className="text-2xl font-semibold">
          Oh snap! seems like you are lost
        </p>
        <p className="text sm">
          You are seeing this because you are not logged in to Linkedin or you
          do not have the extension installed
        </p>
        <p>
          If you have already installed the extension, please login to Linkedin
          and click on the extension
        </p>
        <Link
          to={
            "https://chromewebstore.google.com/detail/leadseeder/ccabkgkocobdhpnlofbdonmdnpnnjaga?hl=en-GB&authuser=0"
          }
          replace={true}
          className="p-2 bg-mainColor text-white rounded-md"
        >
          Install Extension
        </Link>
      </div>
    );
  }

  return (
    <main className=" max-h-[100vh] flex overflow-hidden">
      <div className="w-auto">
        <Sidebar />
      </div>
      <div className="flex flex-col w-full max-w-screen">
        <Navbar />
        <div className=" w-full ">
          <div className="bg-primary p-2 rounded-md w-full h-[100vh]">
            <Outlet />
          </div>
        </div>
      </div>

      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </main>
  );
};

export default DefaultLayout;
