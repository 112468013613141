import React, { useEffect, useState } from "react";
import { FaPaperPlane, FaPlus } from "react-icons/fa6";
import TemplateModal from "../Modals/TemplateModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState, ownerSelector, templateSelector } from "../../store/store";
import { setSelectedTemplate } from "../../store/slices/templateSlice";
import { MdCancel, MdClose, MdDelete } from "react-icons/md";
import { Checkbox } from "antd";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { deleteTemplate } from "../../store/features/ownerActions";
import { toast } from "react-toastify";

type Props = {};

const MessageTemplateMain = (props: Props) => {
  const { templates } = useSelector(templateSelector);
  const { owner } = useSelector(ownerSelector);
  const dispatch: ThunkDispatch<RootState, undefined, Action> = useDispatch();
  const [selectedTemplates, setSelectedTemplates] = useState<String[]>([]);
  const [isMounted, setIsMounted] = React.useState(false);
  const [showTemplateModal, setShowTemplateModal] = useState(false);

  useEffect(() => {
    // console.log("Templates", selectedTemplates);s
    // console.log(templates);
    const timer = setTimeout(() => {
      setIsMounted(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, [selectedTemplates]);
  if (!isMounted) {
    return (
      <div className="flex flex-col flex-wrap">
        <h1 className="text-3xl font-bold">Message Templates</h1>

        {Array(4)
          .fill(0)
          .map((_, index) => (
            <div key={index} className="w-full  flex flex-col  p-2">
              <div className="bg-white p-3 rounded-lg flex  justify-between cursor-pointer items-center w-full shadow-md border-2 border-mainColor/30 animate-pulse">
                <div className="flex gap-2 p-2">
                  {/* <FaPaperPlane className="text-2xl text-mainColor" /> */}
                  <p className="text-md rounded-lg bg-gray-200 p-2  w-12 font-semibold"></p>

                  <p className="text-md rounded-lg bg-gray-200 p-2  w-[150px] font-semibold"></p>
                </div>
                <div className="mr-8">
                  <p className="text-gray-500 bg-gray-200 p-2 rounded-lg w-[400px]"></p>
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  }

  const handleTemplateDelete = async () => {
    try {
      const res = await dispatch(
        deleteTemplate({ templateIds: selectedTemplates, ownerId: owner?._id })
      );

      if (res.meta.requestStatus === "fulfilled") {
        setSelectedTemplates([]);
        toast.success("Template deleted successfully");
      } else {
        toast.error("Failed to delete template");
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to delete template");
    }
  };

  return (
    <section className="flex flex-col gap-4 text-xs">
      <div className="flex justify-between">
        <h1 className="text-xl font-bold">Message Templates</h1>
        <button
          onClick={() => setShowTemplateModal(true)}
          className="bg-mainColor text-white p-2 rounded-lg flex gap-2 justify-center items-center shadow-md font-semibold"
        >
          <FaPlus className="text-white" size={20} />
          <p>Create Template</p>
        </button>
      </div>
      <div className="flex flex-col flex-wrap">
        {selectedTemplates.length > 0 && (
          <div className="flex my-2 justify-between items-center gap-4">
            <div className="flex justify-center items-center gap-4 text-xs">
              <span className=" cursor-pointer">
                <MdClose
                  onClick={() => setSelectedTemplates([])}
                  className=""
                  size={20}
                />
              </span>
              <p className="text-sm font-semibold">
                {selectedTemplates.length} selected
              </p>

              <button
                onClick={() => {
                  setSelectedTemplates(
                    templates.map((template: any) => template._id)
                  );
                }}
                className="bg-mainColor text-white p-2 rounded-lg flex gap-2 justify-center items-center shadow-md font-semibold"
              >
                <p>Select All</p>
              </button>
            </div>
            <button
              onClick={() => {
                handleTemplateDelete();
              }}
              className="bg-primary text-red-500 p-2 rounded-lg flex gap-2 justify-center items-center shadow-md font-semibold border-2 border-red-500"
            >
              <MdDelete className="" size={20} />
              <p>Delete</p>
            </button>
          </div>
        )}
        {templates.length > 0 ? (
          templates.map((template: any) => (
            <div key={template._id} className="w-full  flex gap-2  p-2">
              <Checkbox
                checked={selectedTemplates.includes(template._id)}
                onChange={() => {
                  if (selectedTemplates.includes(template._id)) {
                    setSelectedTemplates((prev) =>
                      prev.filter((item) => item !== template._id)
                    );
                  } else {
                    setSelectedTemplates((prev) => [...prev, template._id]);
                  }
                }}
                className=" bg-main"
              />
              <div
                onClick={() => {
                  console.log(template);
                  setShowTemplateModal(true);
                  dispatch(setSelectedTemplate(template));
                }}
                className="bg-white p-2 rounded-lg flex  justify-between cursor-pointer items-center w-full shadow-md border-2 border-mainColor/30"
              >
                <div className="flex gap-2 p-2 justify-center items-center">
                  <FaPaperPlane size={20} className=" text-mainColor" />
                  <h2 className="text-md font-semibold">{template?.name}</h2>
                </div>
                <div className="mr-8">
                  <p className="text-gray-500">
                    {template?.content?.body?.substring(0, 100) + "..."}
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="w-full h-full flex justify-center items-center">
            <p className="text-2xl text-gray-400">No templates found</p>
          </div>
        )}
      </div>
      {showTemplateModal && (
        <TemplateModal
          // onTemplateChange={(value) => {
          //   console.log(value);
          //   dispatch(setSelectedTemplate(value));
          // }}
          onClose={() => setShowTemplateModal(false)}
        />
      )}
    </section>
  );
};

export default MessageTemplateMain;
