import React, { useState } from "react";
import MembersList from "./MembersList";
import { MdPersonAdd } from "react-icons/md";
import AddMemberModal from "./AddMemberModal";

type Props = {};

const TeamsMain = (props: Props) => {
  const [showAddmemberModal, setShowAddmemberModal] = useState(false);

  return (
    <section className="bg-white rounded-md h-screen p-4">
      <div className="flex justify-between">
        <div>
          <h1 className="text-2xl font-semibold">Collaborators & Team</h1>
          <p className="text-sm">Invite your team members to LeadSeeder</p>
        </div>
        <button
          onClick={() => setShowAddmemberModal(true)}
          className="text-white bg-mainColor flex rounded-md p-2  justify-center' items-center  gap-2 "
        >
          <MdPersonAdd size={20} className=" " />
          <p className="text-sm">Add Member</p>
        </button>
      </div>
      <div>
        <MembersList />
      </div>

      {showAddmemberModal && (
        <AddMemberModal onClose={() => setShowAddmemberModal(false)} />
      )}
    </section>
  );
};

export default TeamsMain;
