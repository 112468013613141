import React from "react";
import TeamsMain from "../components/Teams/TeamsMain";

type Props = {};

const Teams = (props: Props) => {
  return (
    <div>
      <TeamsMain />
    </div>
  );
};

export default Teams;
