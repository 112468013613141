import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

export const fetchCampaignData = createAsyncThunk(
  "campaign/fetchCampaignData",
  async (campaignId: string, { rejectWithValue }) => {
    try {
      const response = await axios.post("/campaign/getCampaignData", {
        campaignId,
      });

      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);
