import { Popover } from "antd";
import React from "react";
import { BsPostcardFill } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import { FaUserGroup } from "react-icons/fa6";
import { MdBarChart, MdInfoOutline } from "react-icons/md";
import { useSelector } from "react-redux";
import { dashboardSelector } from "../../store/store";

type Props = {};

const LinkedinStats = (props: Props) => {
  const { linkedinData } = useSelector(dashboardSelector);
  return (
    <div className="bg-white rounded-lg w-1/2 flex flex-col items-start justify-start gap-4 p-4 shadow-md relative">
      <div className="absolute right-2">
        <Popover
          placement="top"
          content={
            <p className="text-xs">
              Your LinkedIn profile analytics of last 90 days
            </p>
          }
        >
          <MdInfoOutline size={16} />
        </Popover>
      </div>
      <p className="p-1 flex text-xs gap-1 bg-mainColor/20 rounded-lg">
        <MdBarChart size={15} className="text-mainColor" />
        Last 90 days
      </p>
      <div className="flex w-full justify-evenly items-center gap-4">
        <span className="flex flex-col justify-center items-center gap-4">
          <p className="text-mainColor p-2 text-5xl bg-primary rounded-full">
            <FaUserGroup size={20} />
          </p>
          <p className="text-xl font-semibold">
            {linkedinData.numberOfFollowers || 0}
          </p>
          <p className="text-xs">Followers</p>
        </span>
        <span className="flex flex-col justify-center items-center gap-4">
          <p className="text-mainColor p-2 text-5xl bg-primary rounded-full">
            <FaSearch size={20} />
          </p>
          <p className="text-xl font-semibold">
            {linkedinData.numberOfSearchAppearances || 0}
          </p>
          <p className="text-xs">Search Appearances</p>
        </span>
        <span className="flex flex-col justify-center items-center gap-4">
          <p className="text-mainColor p-2 text-5xl bg-primary rounded-full">
            <BsPostcardFill size={20} />
          </p>
          <p className="text-xl font-semibold">
            {linkedinData.numberOfPostImpressions || 0}
          </p>
          <p className="text-xs">Post Impressions</p>
        </span>
      </div>
    </div>
  );
};

export default LinkedinStats;
