// const ENV = "DEV";
// const ENV = "STAGE";
const ENV = "PROD";

const URLS = {
  DEV: {
    BASE: "http://localhost:8080/api/v1",
    PAYMENT: "http://localhost:8080/payments",
    INTERNAL_TOOLS: "http://localhost:8080/internal-tools",
    SOCKET: "ws://localhost:8080",
  },
  // STAGE: {
  //   BASE: "https://signature-backend-staging.boloforms.com/api/v1/signature",
  //   PAYMENT: "https://signature-backend-staging.boloforms.com/payments",
  //   INTERNAL_TOOLS:
  //     "https://signature-backend-staging.boloforms.com/internal-tools",
  // },
  PROD: {
    BASE: "https://backend.leadseeder.co/api/v1",
    PAYMENT: "https://backend.leadseeder.co/payments",
    INTERNAL_TOOLS: "https://backend.leadseeder.co/internal-tools",
    SOCKET: "wss://backend.leadseeder.co",
  },
};

export const SOCKET_URL = URLS[ENV].SOCKET;

export const LINKEDIN_URLS = {
  searchUrl:
    "https://www.linkedin.com/search/results/people/?facetNetwork=%5B%22F%22%5D&origin=CLUSTER_EXPANSION",
  connectionUrl:
    "https://www.linkedin.com/mynetwork/invite-connect/connections/",
  groupUrl: "https://www.linkedin.com/groups/",
  eventUrl: "https://www.linkedin.com/mynetwork/network-manager/events/",
};

export const BASE_URL = URLS[ENV].BASE;
