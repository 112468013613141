import { useEffect, useState } from "react";

import {
  MdGroup,
  MdHomeFilled,
  MdMenu,
  MdRocketLaunch,
  MdSettings,
} from "react-icons/md";
// import { HiTemplate } from "react-icons/hi";
import { HiLightBulb } from "react-icons/hi";

import { FaPeopleGroup, FaVideo, FaYoutube } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { useWindowResize } from "../../hooks/useWindowResize";
import { LOGO } from "../assets";
// import { FcTemplate } from "react-icons/fc";

type Props = {};

const navItems = [
  {
    key: "2",
    icon: <FaPeopleGroup size={20} />,
    label: "Prospects",
    alias: "prospects",
    path: "/prospects",
  },
  {
    key: "3",
    icon: <MdRocketLaunch size={20} />,
    label: "Campaigns",
    alias: "campaigns",
    path: "/campaigns/list/running",
  },
  // {
  //   key: "4",
  //   icon: <MdGroup size={25} />,
  //   label: "Teams",
  //   alias: "teams",
  //   path: "/teams",
  // },
];

const Sidebar = (props: Props) => {
  const [pathName, setPathName] = useState(window.location.pathname);
  const [showSidebar, setShowSidebar] = useState(true);
  const navigate = useNavigate();
  const { width } = useWindowResize();

  useEffect(() => {
    console.log(window.location.pathname);
    setPathName(window.location.pathname);
  }, [window.location.pathname]);

  return (
    <aside
      className={` border-r ${
        showSidebar
          ? ` ${width < 1920 ? "w-[230px]" : "w-[300px]"} text-xs`
          : "w-auto"
      } p-1 bg-white h-[100vh]`}
    >
      <ul className={`flex flex-col h-full justify-between relative  text-xs`}>
        <div className="flex flex-col gap-2">
          <li className="p-1   text-gray-900">
            <div
              // onClick={() => setShowSidebar(!showSidebar)}
              className={` flex  rounded-lg py-2   ${
                showSidebar ? "justify-between" : "justify-center"
              } items-center`}
            >
              {showSidebar ? (
                <span className="font-bold w-32">
                  <img src={LOGO} alt="" />
                </span>
              ) : null}
              {showSidebar && (
                <span className="  text-xs">
                  <p className=" text-xs p-1 bg-black/80 rounded-lg text-white leading-3">
                    Beta
                  </p>
                </span>
              )}
              <span
                onClick={() => setShowSidebar(!showSidebar)}
                className=" cursor-pointer text-xl"
              >
                <p className="text-xs rounded-lg text-gray-800 leading-3">
                  <MdMenu size={20} />
                </p>
              </span>
            </div>
          </li>
          <li className="px-2 text-gray-900">
            <Link
              onClick={() => setPathName("/")}
              to={"/"}
              className={`${
                pathName === "/"
                  ? "bg-gray-100 text-gray-900 border-l-4 border-mainColor"
                  : " border-l-4 border-white"
              } flex  rounded-lg   p-2  justify-start gap-4 items-center hover:bg-mainColor/20 transition-all ease-in-out duration-100 `}
            >
              <span>
                <MdHomeFilled size={20} />
              </span>
              {showSidebar && (
                <span className="font-semibold text-xs">Home</span>
              )}
            </Link>
          </li>
          {navItems.map((item) => (
            <li key={item.key} className="px-2 text-gray-900">
              <Link
                onClick={() => setPathName(item.path)}
                to={item.path}
                className={`${
                  pathName.includes(item.alias)
                    ? "bg-gray-100 text-gray-900 border-l-4 border-mainColor"
                    : " border-l-4 border-white"
                } flex  rounded-lg  p-2  justify-start gap-4 items-center hover:bg-mainColor/20 transition-all ease-in-out duration-100 `}
              >
                <span>{item.icon}</span>
                {showSidebar && (
                  <span className="font-semibold text-xs">{item.label}</span>
                )}
              </Link>
            </li>
          ))}
          <li>
            <div className=" w-full px-2">
              <div
                className={`${
                  pathName === "/faq"
                    ? "bg-gray-100  border-l-4 border-mainColor"
                    : "border-l-4 border-white"
                } flex  rounded-lg   p-2  justify-start gap-4 items-center hover:bg-mainColor/20  cursor-pointer transition-all ease-in-out duration-100`}
              >
                <MdGroup size={20} />
                {showSidebar && (
                  <div className="flex gap-4 items-center justify-center items-center">
                    <span className="font-semibold text-xs ">Teams</span>
                    <small className="bg-mainColor text-white p-[1px] px-[5px] rounded-md">
                      Coming Soon
                    </small>
                  </div>
                )}
              </div>
            </div>
          </li>
        </div>

        <div className=" mb-[2rem] flex flex-col gap-2 text-gray-900">
          <li>
            <div className=" w-full px-2">
              <Link
                target="_blank"
                to="https://cal.com/vidit-gupta/queries?month=2024-07&date=2024-07-19"
                className={`${
                  pathName === "/faq"
                    ? "bg-gray-100  border-l-4 border-mainColor"
                    : "border-l-4 border-white"
                } flex  rounded-lg   p-2  justify-start gap-4 items-center hover:bg-mainColor/20  transition-all ease-in-out duration-100`}
              >
                <FaVideo size={20} />
                {showSidebar && (
                  <span className="font-semibold text-xs">Book a Demo</span>
                )}
              </Link>
            </div>
          </li>
          <li>
            <div className=" w-full px-2">
              <Link
                target="_blank"
                to="https://youtu.be/lNgSm3oWCpM"
                className={`${
                  pathName === "/faq"
                    ? "bg-gray-100  border-l-4 border-mainColor"
                    : "border-l-4 border-white"
                } flex  rounded-lg   p-2  justify-start gap-4 items-center hover:bg-mainColor/20  transition-all ease-in-out duration-100`}
              >
                <FaYoutube size={20} />
                {showSidebar && (
                  <span className="font-semibold text-xs">Tutorial Video</span>
                )}
              </Link>
            </div>
          </li>
          <li>
            <div className=" w-full px-2  text-xs">
              <Link
                target="_blank"
                to="https://leadseeder.canny.io/"
                className={`${
                  pathName === "/faq"
                    ? "bg-gray-100  border-l-4 border-mainColor"
                    : "border-l-4 border-white"
                } flex  rounded-lg   p-2  justify-start gap-4 items-center hover:bg-mainColor/20  transition-all ease-in-out duration-100`}
              >
                <HiLightBulb size={20} />
                {showSidebar && (
                  <span className="font-semibold text-xs ">
                    Feature Request
                  </span>
                )}
              </Link>
            </div>
          </li>

          <li className="px-2 ">
            <Link
              onClick={() => setPathName("/settings/profile")}
              to="/settings/profile"
              className={`${
                pathName.includes("/settings")
                  ? "bg-mainColor/10 text-gray-900 border-l-4 border-mainColor"
                  : "border-l-4 border-white"
              } flex   text-xs rounded-lg   p-2  justify-start gap-4 items-center hover:bg-mainColor/20  transition-all ease-in-out duration-100`}
            >
              <MdSettings size={20} />
              {showSidebar && (
                <span className="font-semibold text-xs">Settings</span>
              )}
            </Link>
          </li>
        </div>
      </ul>
    </aside>
  );
};

export default Sidebar;
