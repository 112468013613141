import React from "react";
import CreateModal from "../../components/Campaigns/CreateModal";

type Props = {};

const CreateCampaign = (props: Props) => {
  return (
    <div className="h-full w-full">
      <CreateModal onClose={() => {}} />
    </div>
  );
};

export default CreateCampaign;
