import { Percent } from "antd/es/progress/style";
import Draggable from 'react-draggable';
import "../ImportProgressLoader.css";

type Props = {
  success: number,
  scanned: number,
  duplicates: number,
  falsePositives: number,
  percent: number,
  visibility: string,
}

const ImportProgressLoader = (props: Props) => {
    return(
    <Draggable>
    <div id="inProgressPanel" className={props.visibility}>
        <div className="flex flex-col min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            className="w-[100%] relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3">
                  <h3 className="text-xl mb-2 font-semibold leading-6 text-gray-900" id="modal-title">
                    Importing Prospects into LeadSeeder
                  </h3>
                  <div id="progresspercent">{props.percent}% Done</div>
                  <div className="progress"> 
                    <div className="progressbar" style={{ width: props.percent + "%", }}></div> 
                </div> 
                  <div className="flex flex-col">
                    <div id="progressstatus">
                      <p><span id="scanprogress">{props.scanned}</span> scanned</p>
                      <p><span id="successprogress">{props.success}</span> success</p>
                      <p><span id="duplicateprogress">{props.duplicates}</span> duplicates</p>
                      <p><span id="falseprogress">{props.falsePositives}</span> false positives</p>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 flex justify-start">
                      <button id="stopSearching" type="button" className="bg-red-500 p-2 rounded-md text-white">
                        Stop Import
                      </button>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>


        </div>
      </div>
      </Draggable>
    );
}

export default ImportProgressLoader;