import React, { useEffect } from "react";
import Campaignbar from "../../components/Campaigns/Campaignbar";
import CampaignMain from "../../components/Campaigns/CampaignMain";
import MessageTemplateMain from "../../components/Templates/MessageTemplateMain";
import { useParams } from "react-router-dom";

type Props = {};

const Campaigns = (props: Props) => {
  const path = window.location.pathname;
  // console.log(path);
  // const { id } = useParams();
  // console.log(id);

  const [activePath, setActivePath] = React.useState(path.split("/")[2]);

  useEffect(() => {
    setActivePath(path.split("/")[2]);
  }, [path]);
  return (
    <div className="flex h-full w-full">
      <Campaignbar />
      <div className="p-4 w-[80%]">
        {activePath === "list" ? <CampaignMain /> : <MessageTemplateMain />}
      </div>
    </div>
  );
};

export default Campaigns;
